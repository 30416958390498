import React, { useState } from 'react'
import Slider from "react-slick";
import { Link } from "react-router-dom"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './index.scss'

const CarouselAlbums = ({ albums }) => {
  const [state, setState] = useState({
    clientXonMouseDown: null,
    clientYonMouseDown: null
  })
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 2.5,
        centerMode: true,
        slidesToScroll: 1,
        centerPadding: '0',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              centerPadding: '15%',
            }
          }
        ]
      };

    const handleOnMouseDown = (e) => {
        setState({
          clientXonMouseDown: e.clientX,
          clientYonMouseDown: e.clientY
        })
        e.preventDefault() // stops weird link dragging effect
      }
    
    const handleOnClick = (e) => {
        e.stopPropagation()
        if (state.clientXonMouseDown !== e.clientX || 
            state.clientYonMouseDown !== e.clientY) {
          // prevent link click if the element was dragged
          e.preventDefault()
        }
      }

    return (
        <Slider {...settings}>
          {
            albums.map((album) => {
              return (
                <div className="albumInfosContainer" key={album?.slug}>
                  <Link onMouseDown={e => handleOnMouseDown(e)} onClick={e => handleOnClick(e)} custom-link-name={`${album.title} Album`} to={`/album/${album.slug}`}>
                    <img src={album.pochette} alt="" />
                    <h2>{album.title}</h2>
                    <p>{album.subtitle}</p>
                  </Link>
                </div>
              )
            })
          }
        </Slider>
    )
}

export default CarouselAlbums