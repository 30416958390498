import React from 'react'
import FacebookIcon from '../../assets/icons/facebook-logo.png'
import InstagramIcon from '../../assets/icons/instagram.png'
import TiktokIcon from '../../assets/icons/tik-tok.png'
import TwitterIcon from '../../assets/icons/twitter.png'
import YoutubeIcon from '../../assets/icons/youtube.png'
import './index.scss'

const TrackSocialMedia = () => {
    return (
        <div className="trackSocialMedia">
            <ul className="socialMedias">
                <li>
                    <a href="https://www.facebook.com/AmirPageOfficielle" target="_blank" rel="noreferrer">
                        <img src={FacebookIcon} alt="" />
                    </a>
                </li>

                <li>
                    <a href="https://www.instagram.com/amir_officiel_" target="_blank" rel="noreferrer">
                        <img src={InstagramIcon} alt="" />
                    </a>
                </li>

                <li>
                    <a href="https://twitter.com/Amir_Off" target="_blank" rel="noreferrer">
                        <img src={TwitterIcon} alt="" />
                    </a>
                </li>

                <li>
                    <a href="https://www.youtube.com/channel/UChcrhsMDYCTf8tcMwn2K5-w" target="_blank" rel="noreferrer">
                        <img src={YoutubeIcon} alt="" />
                    </a>
                </li>

                <li>
                    <a href="https://www.tiktok.com/@amir_officiel_?lang=fr" target="_blank" rel="noreferrer">
                        <img src={TiktokIcon} alt="" />
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default TrackSocialMedia