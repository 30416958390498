import React from 'react'
import './index.scss'
import { Link } from 'react-router-dom'

const Title = ({ text }) => {
    return (
        <Link className="mainTitle" className="titleLink" to={"/"}><h1 dangerouslySetInnerHTML={{ __html: text }} /></Link>
    )
}

export default Title