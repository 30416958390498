import React from 'react'
import { Link } from 'react-router-dom'
import { albums } from '../../data/albums'
import FacebookIcon from '../../assets/icons/facebook-logo.png'
import InstagramIcon from '../../assets/icons/instagram.png'
import TiktokIcon from '../../assets/icons/tik-tok.png'
import TwitterIcon from '../../assets/icons/twitter.png'
import YoutubeIcon from '../../assets/icons/youtube.png'
import CloseIcon from '../../assets/icons/cross.png'
import './index.scss'

const Menu = ({ isOpenMenu, setIsOpenMenu }) => {
    return (
        <nav className={`menu ${isOpenMenu ? 'open' : ''}`}>
            <img onClick={() => setIsOpenMenu(false)} className="closeMenu" src={CloseIcon} alt="Fermer me menu" />
            <ul className="socialMedias">
                <li>
                    <a href="https://www.facebook.com/AmirPageOfficielle" target="_blank" rel="noreferrer">
                        <img src={FacebookIcon} alt="" />
                    </a>
                </li>

                <li>
                    <a href="https://www.instagram.com/amir_officiel_" target="_blank" rel="noreferrer">
                        <img src={InstagramIcon} alt="" />
                    </a>
                </li>

                <li>
                    <a href="https://twitter.com/Amir_Off" target="_blank" rel="noreferrer">
                        <img src={TwitterIcon} alt="" />
                    </a>
                </li>

                <li>
                    <a href="https://www.youtube.com/channel/UChcrhsMDYCTf8tcMwn2K5-w" target="_blank" rel="noreferrer">
                        <img src={YoutubeIcon} alt="" />
                    </a>
                </li>

                <li>
                    <a href="https://www.tiktok.com/@amir_officiel_?lang=fr" target="_blank" rel="noreferrer">
                        <img src={TiktokIcon} alt="" />
                    </a>
                </li>
            </ul>

            <ul className="albumList">
                {
                    albums.map((album) => {
                        return (
                            <Link custom-link-name={`${album.title} Album`} onClick={() => setIsOpenMenu(false)} key={album?.slug} to={"/album/" + album?.slug}>
                                <li>{album.title}</li>
                            </Link>
                        )
                    })
                }
            </ul>
        </nav>
    )
}

export default Menu