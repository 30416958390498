import React from "react";
import "./index.scss";

const Footer = () => {
  return (
    <section className="footer">
      <ul className="flex center footer-list">
        <li className="footer-list-item light">
          <a
            href="http://www.warnermusic.fr/Mentions-legales"
            target="_blank"
            rel="noreferrer"
          >
            Mentions Légales
          </a>
        </li>
        <li className="footer-list-item light">
          <a
            href="https://www.wminewmedia.com/terms-of-use/fr/"
            target="_blank"
            rel="noreferrer"
          >
            Conditions générales d’utilisation
          </a>
        </li>
        <li className="footer-list-item light">
          <a
            href="https://www.wminewmedia.com/cookies-policy/fr/"
            target="_blank"
            rel="noreferrer"
          >
            Gestion des cookies
          </a>
        </li>
        <li className="footer-list-item light">
          <a
            href="https://www.wminewmedia.com/privacy/fr/"
            target="_blank"
            rel="noreferrer"
          >
            Politique de Traitement des données
          </a>
        </li>

        <li className="footer-list-item light">
          <a className="ot-sdk-show-settings" style={{ cursor: "pointer" }}>
            Paramétrer mes cookies
          </a>
        </li>
        <li className="footer-list-item light">
          <p>© 2024 Warner Music France</p>
        </li>
      </ul>
    </section>
  );
};

export default Footer;
