import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AlbumIntro from "../../components/albumIntro";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Title from "../../components/title";
import TrackSocialMedia from "../../components/trackSocialMedias";
import { tracks, albums } from "../../data/albums";
import LogoAmir from "../../assets/img/mainLogo.png";
import { sendData } from "../../utils";
import { Helmet } from "react-helmet";
import "./index.scss";
import FormWall from "../../components/formWall";

const Track = ({ setIsOpenMenu }) => {
  const { id } = useParams();
  const [track] = useState(tracks.find((track) => track.slug === id));
  const [album] = useState(
    albums.find((album) => album.slug === track?.album) || null
  );

  useEffect(() => {
    window.reloadOTBanner();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (track) {
      const objectDigitalData = {
        settings: {
          reportSuites: "wmg,wmgfr",
        },
        page: {
          pageInfo: {
            pageName: `Amir:Music:${track.title}`,
            server: "Amir:Site",
            platform: "Static HTML",
            devTeam: "Warner Music France",
          },
          category: {
            primaryCategory: "Amir:Music",
            pageType: "music:track details",
          },
        },
        content: {
          artist: "Amir",
          label: "Warner Music International",
          sublabel: "Warner Music France",
        },
      };

      sendData(objectDigitalData);
    }
  }, [track]);

  return (
    <main className="track">
      <Helmet>
        <title>Amir-Paroles | {track?.title}</title>
      </Helmet>

      <FormWall />

      <Header setIsOpenMenu={setIsOpenMenu} backUrl={"/album/" + album?.id} />
      <Title text={`<img src='${LogoAmir}' alt='' /> <span>paroles</span>`} />
      {album && <AlbumIntro vertical={true} album={album} />}
      {track && (
        <>
          <h2 dangerouslySetInnerHTML={{ __html: track?.title }} />

          <div
            className="lyrics"
            dangerouslySetInnerHTML={{
              __html: track?.lyric?.replaceAll("/ ", "<br />"),
            }}
          />

          <p
            className="credits"
            dangerouslySetInnerHTML={{ __html: track?.credits }}
          />
          {track?.ytId && (
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${track?.ytId}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          )}
          <TrackSocialMedia />
        </>
      )}

      <picture>
        <source
          srcSet="/assets/img/amir-desktop.png"
          media="(min-width: 1024px)"
        />
        <img className="amirProfil1" src="/assets/img/amir-profil1.png" />
      </picture>
      <img className="amirProfil2" src="/assets/img/amir-profil2.png" />
      <Footer />
    </main>
  );
};

export default Track;
